<template>
  <div class="main-container">
    <Header />
    <SearchResultPage />
    <Footer />
    <OffCanvasMobileMenu />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import SearchResultPage from "../components/SearchResultPage.vue";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

export default {
  components: {
    Header,
    SearchResultPage,
    Footer,
    OffCanvasMobileMenu,
  },
  metaInfo: {
    title: "Putra Ramayana - Search Produk",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
};
</script>
