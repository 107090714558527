<template>
  <div class="main-wrapper">
    <!-- Page Section Start -->
    <div class="page-section section section-padding section-space--inner--60">
      <div v-if="showsearch == false">
        <div class="row justify-content-center" style="margin: 40px 0px">
          <div class="col-12 col-md-5">
            <div class="input-group">
              <input
                type="text"
                v-model="search"
                class="form-control"
                placeholder="Nama Produk"
              />
              <div class="input-group-prepend">
                <button class="btn btn-primary">
                  <a :href="/search/ + this.search">
                    <i class="fa fa-search"></i
                  ></a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-5 text-center">
            <p>
              Hasil Pencarian
              <span style="font-weight: bold"
                >"{{ this.$route.params.search }}"</span
              >
              Tidak Ditemukan. Silahkan Coba dengan Kata Kunci lainnya.
            </p>
          </div>
        </div>
      </div>
      <!-- SHOW DATA -->
      <!-- CARI -->
      <div class="container" v-if="showsearch == true">
        <div class="row row-30 mb-40">
          <div
            class="col-xl-9 col-lg-8 col-12 order-1 order-lg-2"
            style="margin-bottom: 40px"
          >
            <!--  Show Data -->
            <div class="row">
              <div
                v-for="cari in caris"
                v-bind:key="cari.nama_produk"
                class="col-xl-3 col-md-4 col-6 product-card"
              >
                <router-link
                  :to="
                    /detil-product/ +
                    cari.id_produk +
                    /jenis-produk/ +
                    cari.jenis_produk +
                    /merk-produk/ +
                    cari.merk_produk +
                    /nama-produk/ +
                    cari.nama_produk
                  "
                >
                  <div class="card">
                    <img
                      class="card-img-top"
                      :src="cari.url_gambar_produk"
                      alt="Card image cap"
                    />
                    <div class="card-body">
                      <h5 class="card-title">
                        <div v-if="cari.nama_produk.length < 40">
                          <p class="title">{{ cari.nama_produk }}</p>
                        </div>
                        <div v-else>
                          <p class="title">
                            {{ cari.nama_produk.substring(0, 35) + "..." }}
                          </p>
                        </div>
                      </h5>

                      <p class="p-price">
                        <!-- {{ formatPrice(hargamin.harga_produk) }} -
                          {{ formatPrice(hargamax.harga_produk) }} -->
                        Harga di Detail Produk
                      </p>

                      <p class="p-merk">
                        {{ cari.jenis_produk }} {{ cari.merk_produk }}
                      </p>
                    </div>
                  </div></router-link
                >
                <!-- <div class="product-item">
                  <div class="product-inner">
                    <div class="image">
                      <img
                        :src="cari.url_gambar_produk"
                        :alt="cari.nama_produk"
                      />

                      <div class="image-overlay">
                        <div class="action-buttons">
                          <router-link
                            :to="
                              /detil-product/ +
                              cari.id_produk +
                              /jenis-produk/ +
                              cari.jenis_produk +
                              /merk-produk/ +
                              cari.merk_produk +
                              /nama-produk/ +
                              cari.nama_produk
                            "
                          >
                            <a href="">
                              <button>Lihat Produk</button>
                            </a></router-link
                          >
                        </div>
                      </div>
                    </div>

                    <div class="content">
                      <div class="content-left">
                        <p class="title">{{ cari.nama_produk }}</p>
                        <p>{{ cari.merk_produk }}</p>
                        <h5 class="jenis">Jenis: {{ cari.jenis_produk }}</h5>
                        <h5 class="tipe">Harga Cek Detail Produk</h5>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-12 order-2 order-lg-1">
            <div class="sidebar">
              <a href="">
                <h4 class="sidebar-title">
                  Hasil Pencarian : "{{ this.$route.params.search }}"
                </h4>
              </a>

              <ul class="sidebar-list">
                <li v-for="kategori in caris" :key="kategori.id_kategori">
                  <a
                    :href="
                      /detil-product/ +
                      kategori.id_produk +
                      /jenis-produk/ +
                      kategori.jenis_produk +
                      /merk-produk/ +
                      kategori.merk_produk +
                      /nama-produk/ +
                      kategori.nama_produk
                    "
                    >{{ kategori.nama_produk }} - {{ kategori.merk_produk }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Page Section End -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      caris: [],
      produkkategoris: [],
      search: "",
      filter: "",
      showsearch: false,
    };
  },

  methods: {
    handleSearch() {
      this.$router.push("/search/" + this.search);
    },

    searchProduct() {
      axios
        .get(
          "https://admin.putraramayana.com/api/produks/getbynama/" +
            this.$route.params.search
        )
        .then((response) => {
          if (response.data.length > 0) {
            this.caris = response.data;
            this.showsearch = true;
            this.search = "";
          } else this.showsearch = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },

  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.searchProduct();
      }
    );
  },

  mounted() {
    this.searchProduct();
  },
};
</script>
