var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrapper"},[_c('div',{staticClass:"page-section section section-padding section-space--inner--60"},[(_vm.showsearch == false)?_c('div',[_c('div',{staticClass:"row justify-content-center",staticStyle:{"margin":"40px 0px"}},[_c('div',{staticClass:"col-12 col-md-5"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Nama Produk"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-primary"},[_c('a',{attrs:{"href":/search/ + this.search}},[_c('i',{staticClass:"fa fa-search"})])])])])])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-5 text-center"},[_c('p',[_vm._v(" Hasil Pencarian "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("\""+_vm._s(this.$route.params.search)+"\"")]),_vm._v(" Tidak Ditemukan. Silahkan Coba dengan Kata Kunci lainnya. ")])])])]):_vm._e(),(_vm.showsearch == true)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row-30 mb-40"},[_c('div',{staticClass:"col-xl-9 col-lg-8 col-12 order-1 order-lg-2",staticStyle:{"margin-bottom":"40px"}},[_c('div',{staticClass:"row"},_vm._l((_vm.caris),function(cari){return _c('div',{key:cari.nama_produk,staticClass:"col-xl-3 col-md-4 col-6 product-card"},[_c('router-link',{attrs:{"to":/detil-product/ +
                  cari.id_produk +
                  /jenis-produk/ +
                  cari.jenis_produk +
                  /merk-produk/ +
                  cari.merk_produk +
                  /nama-produk/ +
                  cari.nama_produk}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":cari.url_gambar_produk,"alt":"Card image cap"}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[(cari.nama_produk.length < 40)?_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(cari.nama_produk))])]):_c('div',[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(cari.nama_produk.substring(0, 35) + "...")+" ")])])]),_c('p',{staticClass:"p-price"},[_vm._v(" Harga di Detail Produk ")]),_c('p',{staticClass:"p-merk"},[_vm._v(" "+_vm._s(cari.jenis_produk)+" "+_vm._s(cari.merk_produk)+" ")])])])])],1)}),0)]),_c('div',{staticClass:"col-xl-3 col-lg-4 col-12 order-2 order-lg-1"},[_c('div',{staticClass:"sidebar"},[_c('a',{attrs:{"href":""}},[_c('h4',{staticClass:"sidebar-title"},[_vm._v(" Hasil Pencarian : \""+_vm._s(this.$route.params.search)+"\" ")])]),_c('ul',{staticClass:"sidebar-list"},_vm._l((_vm.caris),function(kategori){return _c('li',{key:kategori.id_kategori},[_c('a',{attrs:{"href":/detil-product/ +
                    kategori.id_produk +
                    /jenis-produk/ +
                    kategori.jenis_produk +
                    /merk-produk/ +
                    kategori.merk_produk +
                    /nama-produk/ +
                    kategori.nama_produk}},[_vm._v(_vm._s(kategori.nama_produk)+" - "+_vm._s(kategori.merk_produk))])])}),0)])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }